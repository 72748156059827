@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/fonts';
@use '@/styles/resources/variables';

.root {
    display: flex;
    flex-direction: column;
    margin-top: 2.375rem;

    &.mobileVersion {
        border-top: 1px solid colors.$border-color--tertiary;
        margin-top: 0;
        padding-top: 1.5rem;

        @include responsive.media('>tablet') {
            border-top: unset;
            margin-top: 2.375rem;
            padding-top: 0;
        }
    }
}

.content {
    align-items: center;
    display: flex;
    flex-flow: column;
    gap: 0rem;
    margin-top: 2rem;

    @include responsive.media('>tablet') {
        flex-flow: row;
        gap: 1.875rem;
    }
}

.image {
    height: 100%;
    object-fit: contain;
    object-position: center;

    &Root {
        aspect-ratio: 1 / 1;
        height: 100%;
    }

    &Wrapper {
        align-self: center;
        height: 7.5rem;
        position: relative;
        width: 7.5rem;
    }
}

.form {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    input[type='tel']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    button {
        max-height: 2.5rem;
    }
}

.donationProductTooltipWrapper {
    background-color: colors.$tooltip-background-color;
    border: 1px solid colors.$border-color--tertiary;
    border-radius: variables.$border-radius;
    box-shadow: variables.$header--dropdown-box-shadow;
    color: colors.$tooltip-text-color;
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
    min-width: 6.5rem;
    padding: 1rem;
    right: 0;
    text-align: left;
    transition: opacity 0.16s ease-in-out;
}

.donationProductTooltip {
    @include mixins.donationProductTooltipTitle();
    gap: 0.5rem;
    padding-top: 0.25rem;
    position: absolute;
    width: fit-content;

    > svg {
        margin-left: unset;
        stroke: colors.$color--cornflower-blue;
    }
}

.controlWrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 1.5rem;

    @include responsive.media('>tablet') {
        flex-wrap: nowrap;
        gap: 1rem;
    }

    &.mobileVersion {
        flex-wrap: wrap;
        gap: 1.5rem;
    }
}

.list {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    gap: 0.5rem;

    > * {
        flex: 1 1 auto;
    }

    @include responsive.media('>tablet') {
        gap: 0.8rem;
    }

    &.mobileVersion {
        gap: 0.5rem;
    }
}

.input {
    flex: 1;
    min-height: 4.625rem;
    min-width: 100%;
    width: 100%;

    input {
        padding: 0.85rem;
    }

    sup {
        font-size: 0.85rem;
    }

    @include responsive.media('>tablet') {
        min-width: 50%;
    }

    &.mobileVersion {
        min-width: 100%;
    }
}

.collapseOptionContainer {
    width: 100%;

    .collapseOptionLabel svg {
        transform: rotate(90deg);
        transition: transform 0.16s ease-in-out;
    }
}

.collapseOptionContainerOpened {
    @extend .collapseOptionContainer;

    .collapseOptionLabel svg {
        transform: rotate(-90deg);
    }
}

.collapseOptionLabel {
    align-items: center;
    border-bottom: 1px solid colors.$border-color--secondary;
    display: flex;
    font-size: 1rem;
    font-weight: fonts.$font-weight--medium;
    height: 2rem;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    width: 100%;

    > svg {
        margin-left: auto;
    }
}
