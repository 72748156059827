@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
.option {
  display: flex;
  gap: 0.25rem;
}

.wrapper {
    display: grid;
    gap: 0.25rem;
}
