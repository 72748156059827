@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/variables';

.tooltipCollapsible {
    background-color: colors.$tooltip-background-color;
    border: 1px solid colors.$border-color--tertiary;
    border-radius: variables.$border-radius;
    box-shadow: variables.$header--dropdown-box-shadow;
    color: colors.$tooltip-text-color;
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    min-width: 6.5rem;
    padding: 1rem;
    right: 0;
    text-align: left;
    transition: opacity 0.16s ease-in-out;
}

.tooltipContentWrapper {
    display: grid;
    font-size: 0.875rem;
    gap: 1rem;

    section {
        display: grid;
        gap: 0.25rem;
    }

    h2 {
        @include mixins.heading-5();
    }
}

.tooltipTrigger {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    width: 100%;

    > svg:last-of-type {
        margin-left: auto;
        stroke: colors.$tooltip-text-color--info;
    }
}
